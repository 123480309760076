import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { parseKoDateByDayOfWeek, parseTimeToMinutes } from '../../lib/parseDate';
import parseToAmount from '../../lib/parseToAmount';
import Modal from '../commons/Modal';
import Spinner from '../commons/Spinner';
import ErrorPopup from './ErrorPopup';
import * as alpensiaApi from '../../api/alpensia';

const RoomSelect = ({ isOpen, onClose, data, payYn, hotelCode, confirmationNo }) => {
  const { bookingItem, userInfo } = useSelector((state) => state.booking);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);
  const [ErrorMessageTitle, setErrorMessageTitle] = useState('');
  const [ErrorMessageDescription, setErrorMessageDescription] = useState('');

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const requestPayment = async () => {
    try {
      const { data: responseRequestPayment } = await alpensiaApi.requestPayment({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
      });

      if (responseRequestPayment.resultCode === '1000') {
        window.open(responseRequestPayment.data.authPageUrl, '_self');
      } else {
        throw new Error(`${responseRequestPayment.resultCode}, ${responseRequestPayment.msg}`);
      }
    } catch (error) {
      setErrorMessageTitle('결제 요청 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const requestCheckin = async () => {
    try {
      const { data: responseCheckin } = await alpensiaApi.requestCheckin({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        hotelCode,
        confirmationNo,
        specialCodeYn: true,
        payRoutingYn: true,
      });
      if (responseCheckin.resultCode === '1000')
        navigate(`/?h=${hotelCode}&r=${confirmationNo}`, {
          state: {
            visitedConfirmationNo: confirmationNo,
          },
        });
      else throw new Error(`${responseCheckin.resultCode}, ${responseCheckin.msg}`);
    } catch (error) {
      setErrorMessageTitle('체크인 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const validateAndRequestCheckin = _.debounce(
    async () => {
      try {
        setIsLoading(true);
        if (!payYn) {
          setErrorMessageTitle('객실 선택 실패');
          setErrorMessageDescription('객실을 다시 선택해주세요.');
          openErrorPopup();
          onClose();
        } else if (bookingItem.totalAmount > 0 && payYn === 'Y') requestPayment();
        else await requestCheckin();
      } catch (error) {
        console.log('error: ', JSON.stringify(error.message));
      } finally {
        setIsLoading(false);
      }
    },
    300,
    { maxWait: 1000 },
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        customStyle="pr-0 pl-0"
        Content={
          <>
            <p className="room-select-title mr-20 ml-20">객실 선택</p>
            <div className="horizontal-line mt-12 mb-12"></div>
            <div className="room-select-body-container">
              <p className="resort-name">{bookingItem.hotelName || '-'}</p>
              <span className="roomtype-name mt-1">{bookingItem.roomTypeName || '-'}</span>
              <div className="room-info">
                <span className="room-name">{`${data.roomNo || '-'}호`}</span>
                <span className="building-name">
                  {`[ ${data.building || '-'} / ${data.floor || '-'} ]`}
                </span>
              </div>
              <div className="horizontal-line mt-12 mb-12"></div>
              <div className="period-info">
                <span className="period-info-label">체크인</span>
                <span className="period-info-value">
                  {`${parseKoDateByDayOfWeek(bookingItem.arrivalDate) || '-'} ${
                    parseTimeToMinutes(bookingItem.checkInInfo) || '-'
                  }`}
                </span>
              </div>
              <div className="period-info">
                <span className="period-info-label">체크아웃</span>
                <span className="period-info-value">
                  {`${parseKoDateByDayOfWeek(bookingItem.departureDate) || '-'} ${
                    parseTimeToMinutes(bookingItem.checkOutInfo) || '-'
                  }`}
                </span>
              </div>
              <div className="period-info">
                <span className="period-info-label">숙박일수</span>
                <span className="period-info-value">
                  {`${
                    bookingItem.nights || _.isNumber(bookingItem.nights) ? bookingItem.nights : '-'
                  }박`}
                </span>
              </div>
              <span className="amount">
                {`결제 예정 금액: ${parseToAmount(bookingItem.totalAmount)}원`}
              </span>
              <div className="pl-20 pr-20">
                <button
                  className="basic-button h-40 fs-13 mt-20"
                  onClick={validateAndRequestCheckin}
                >
                  확인 및 결제
                </button>
                <button className="basic-button h-40 fs-13 mt-12 mb-2 bc-gray" onClick={onClose}>
                  취소
                </button>
              </div>
            </div>
          </>
        }
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default RoomSelect;
