import axios from 'axios';

const baseURL = process.env.REACT_APP_ALPENSIA_BACKEND_BASEURL;
const apiKey = process.env.REACT_APP_IMPORT_API_KEY;

// config
const request = axios.create({
  baseURL,
  timeout: 30000,
});

const headers = (header) => {
  return {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json;charset=UTF-8',
      ...(header && header),
    },
  };
};

// 예약 조회
export const getReservation = ({ hotelCode, confirmationNo }) => {
  return request.post('/reservation/getInfo', { hotelCode, confirmationNo }, headers());
};

// 본인인증 암호화데이터 생성
export const getCertification = ({ accessToken, hotelCode, confirmationNo }) => {
  return request.post(
    '/reservation/certification',
    { hotelCode, confirmationNo },
    headers({ Authorization: accessToken }),
  );
};

// 투숙객정보 추가
export const addAccompanyGuest = ({ accessToken, reqNum, retInfo }) => {
  return request.post(
    '/reservation/addAccompanyGuest',
    { reqNum, retInfo },
    headers({ Authorization: accessToken }),
  );
};

// 약관 조회
export const getPolicy = ({ accessToken, hotelCode }) => {
  return request.post(
    '/reservation/getPolicy',
    { hotelCode },
    headers({ Authorization: accessToken }),
  );
};

// 약관동의 등록
export const savePolicyAgree = ({ accessToken, ci, policyAgreeList }) => {
  return request.post(
    '/reservation/savePolicyAgree',
    { ci, policyAgreeList },
    headers({ Authorization: accessToken }),
  );
};

// 가용성 객실목록 조회
export const getAvailableRoomList = ({ accessToken, hotelCode }) => {
  return request.post(
    '/reservation/getAvailableRoomList',
    { hotelCode },
    headers({ Authorization: accessToken }),
  );
};

// 객실 선택 검증
export const roomFixed = ({ accessToken, hotelCode, confirmationNo, roomNo }) => {
  return request.post(
    '/reservation/roomFixed',
    { hotelCode, confirmationNo, roomNo },
    headers({ Authorization: accessToken }),
  );
};

// KICC 결제요청
export const requestPayment = ({ accessToken }) => {
  return request.post('/external/payment/kicc', {}, headers({ Authorization: accessToken }));
};

// KICC 결제결과 확인
export const getPaymentResult = ({ accessToken }) => {
  return request.post('/external/payment/get-info', {}, headers({ Authorization: accessToken }));
};

// 체크인 요청
export const requestCheckin = ({
  accessToken,
  hotelCode,
  confirmationNo,
  specialCodeYn,
  payRoutingYn,
}) => {
  return request.post(
    '/reservation/checkIn',
    { hotelCode, confirmationNo, specialCodeYn, payRoutingYn },
    headers({ Authorization: accessToken }),
  );
};

// 밸런스 체크
export const checkBalance = ({ accessToken, hotelCode, confirmationNo }) => {
  return request.post(
    '/reservation/checkBalance',
    { hotelCode, confirmationNo },
    headers({ Authorization: accessToken }),
  );
};

// 체크아웃 요청
export const requestCheckout = ({ accessToken, hotelCode, confirmationNo }) => {
  return request.post(
    '/reservation/checkOut',
    { hotelCode, confirmationNo },
    headers({ Authorization: accessToken }),
  );
};
