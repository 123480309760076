import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PaymentSuccess from '../components/views/PaymentSuccess';
import ErrorPopup from '../components/views/ErrorPopup';
import Spinner from '../components/commons/Spinner';
import * as alpensiaApi from '../api/alpensia';

const PaymentResultContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenPaymentResultSuccess, setIsOpenPaymentResultSuccess] = useState(false);
  const [paymentSuccessData, setPaymentSuccessData] = useState({});
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);
  const [errorMessageTitle, setErrorMessageTitle] = useState('');
  const [errorMessageDescription, setErrorMessageDescription] = useState('');

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const hotelCode = encodeURIComponent(searchParams.get('h'));
  const confirmationNo = encodeURIComponent(searchParams.get('r'));

  const paymentResult = searchParams.get('success');
  const isPaymentSuccess = paymentResult === 'true';

  const openPaymentSuccess = () => {
    setIsOpenPaymentResultSuccess(true);
  };

  const closePaymentSuccess = () => {
    setIsOpenPaymentResultSuccess(false);
  };

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
    navigate(`/?h=${hotelCode}&r=${confirmationNo}`, { replace: true });
  };

  const getReservation = async () => {
    try {
      const { data: responseGetReservation } = await alpensiaApi.getReservation({
        hotelCode,
        confirmationNo,
      });
      if (responseGetReservation.resultCode === '1000') {
        const { reservInfo, tokenInfo } = responseGetReservation.data;
        return { reservInfo, tokenInfo };
      } else throw new Error(`${responseGetReservation.resultCode}, ${responseGetReservation.msg}`);
    } catch (error) {
      setErrorMessageTitle('예약조회 실패');
      throw error;
    }
  };

  const getPaymentResult = async ({ grantType, accessToken }) => {
    try {
      const { data: responsePaymentResult } = await alpensiaApi.getPaymentResult({
        accessToken: `${grantType} ${accessToken}`,
      });
      if (responsePaymentResult.resultCode === '1000')
        return responsePaymentResult.data.paymentInfoSummary;
      else throw new Error(`${responsePaymentResult.resultCode}, ${responsePaymentResult.msg}`);
    } catch (error) {
      setErrorMessageTitle('결제 실패');
      throw error;
    }
  };

  const requestCheckin = async ({ grantType, accessToken }) => {
    try {
      const { data: responseCheckin } = await alpensiaApi.requestCheckin({
        accessToken: `${grantType} ${accessToken}`,
        hotelCode,
        confirmationNo,
        specialCodeYn: true,
        payRoutingYn: true,
      });
      if (responseCheckin.resultCode === '1000') openPaymentSuccess();
      else throw new Error(`${responseCheckin.resultCode}, ${responseCheckin.msg}`);
    } catch (error) {
      setErrorMessageTitle('체크인 실패');
      throw error;
    }
  };

  const validateAndRequestCheckin = async () => {
    try {
      setIsLoading(true);
      const { reservInfo, tokenInfo } = await getReservation();
      if (reservInfo.comReservationStatus === 'DUEIN') {
        const paymentData = await getPaymentResult(tokenInfo);
        if (paymentData.status === 'paid') {
          setPaymentSuccessData(paymentData);
          await requestCheckin(tokenInfo);
        } else throw new Error(`${paymentData.status}, 결제가 완료되지 않았습니다`);
      } else navigate(`/?h=${hotelCode}&r=${confirmationNo}`);
    } catch (error) {
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!paymentResult) return;

    if (isPaymentSuccess) {
      validateAndRequestCheckin();
      return;
    }

    setErrorMessageTitle('결제 실패');
    setErrorMessageDescription('다시 시도해주세요.');
    openErrorPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentResult]);

  return (
    <>
      <PaymentSuccess
        isOpen={isOpenPaymentResultSuccess}
        onClose={closePaymentSuccess}
        data={paymentSuccessData}
        hotelCode={hotelCode}
        confirmationNo={confirmationNo}
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={errorMessageTitle}
        description={errorMessageDescription}
      />
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default PaymentResultContainer;
