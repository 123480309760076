import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import TermsOfUseList from '../components/views/TermsOfUseList';
import PaymentFail from '../components/views/PaymentFail';
import ErrorPopup from '../components/views/ErrorPopup';
import Spinner from '../components/commons/Spinner';
import { bookingAction } from '../stores/actions';
import * as alpensiaApi from '../api/alpensia';

const TermsOfUseListContainer = () => {
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;

  const dispatch = useDispatch();
  const { bookingItem, userInfo } = useSelector((state) => state.booking);

  const [isLoading, setIsLoading] = useState(false);
  const [termsOfUseList, setTermsOfUseList] = useState([]);
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);
  const [ErrorMessageTitle, setErrorMessageTitle] = useState('');
  const [ErrorMessageDescription, setErrorMessageDescription] = useState('');
  const [isOpenPaymentFailPopup, setIsOpenPaymentFailPopup] = useState(false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const hotelCode = encodeURIComponent(searchParams.get('h'));
  const confirmationNo = encodeURIComponent(searchParams.get('r'));

  const location = useLocation();
  const paymentYn = location.state?.paymentYn;

  const openPaymentFailPopup = () => {
    setIsOpenPaymentFailPopup(true);
  };

  const closePaymentFailPopup = () => {
    setIsOpenPaymentFailPopup(false);
  };

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const getReservation = async () => {
    try {
      const { data: responseGetReservation } = await alpensiaApi.getReservation({
        hotelCode,
        confirmationNo,
      });
      if (responseGetReservation.resultCode === '1000') {
        const { reservInfo, tokenInfo } = responseGetReservation.data;
        dispatch(bookingAction.setBookingItem(reservInfo));
        dispatch(bookingAction.setUserInfo(tokenInfo));
        if (reservInfo.comReservationStatus !== 'DUEIN')
          navigate(`/?h=${hotelCode}&r=${confirmationNo}`);
        return tokenInfo;
      } else {
        dispatch(bookingAction.setBookingItem({}));
        dispatch(bookingAction.setUserInfo({}));
        throw new Error(`${responseGetReservation.resultCode}, ${responseGetReservation.msg}`);
      }
    } catch (error) {
      setErrorMessageTitle('예약조회 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const getPolicy = async ({ grantType, accessToken }) => {
    try {
      const { data: responseGetPolicy } = await alpensiaApi.getPolicy({
        accessToken: `${grantType} ${accessToken}`,
        hotelCode,
      });
      if (responseGetPolicy.resultCode === '1000') {
        const parsedTermsOfUseList = responseGetPolicy.data.servicePolicyList.map((termsOfUse) => {
          termsOfUse.agree_yn = false;
          return termsOfUse;
        });
        setTermsOfUseList(parsedTermsOfUseList);
      } else {
        setTermsOfUseList([]);
        throw new Error(`${responseGetPolicy.resultCode}, ${responseGetPolicy.msg}`);
      }
    } catch (error) {
      setErrorMessageTitle('약관 내용 조회 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const savePolicyAgree = async () => {
    try {
      const pickedTermsOfUseList = termsOfUseList.map((termsOfUse) =>
        _.pick(termsOfUse, ['dataSeq', 'agree_yn']),
      );
      const { data: responseSavePolicyAgree } = await alpensiaApi.savePolicyAgree({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        ci: isTestMode ? 'TEST' : 'PROD',
        policyAgreeList: pickedTermsOfUseList,
      });
      if (responseSavePolicyAgree.resultCode === '1000') return;
      else throw new Error(`${responseSavePolicyAgree.resultCode}, ${responseSavePolicyAgree.msg}`);
    } catch (error) {
      setErrorMessageTitle('약관동의 등록 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const requestPayment = async () => {
    try {
      const { data: responseRequestPayment } = await alpensiaApi.requestPayment({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
      });

      if (responseRequestPayment.resultCode === '1000') {
        window.open(responseRequestPayment.data.authPageUrl, '_self');
      } else {
        throw new Error(`${responseRequestPayment.resultCode}, ${responseRequestPayment.msg}`);
      }
    } catch (error) {
      setErrorMessageTitle('결제 요청 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const requestCheckin = async () => {
    try {
      const { data: responseCheckin } = await alpensiaApi.requestCheckin({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        hotelCode,
        confirmationNo,
        specialCodeYn: true,
        payRoutingYn: true,
      });
      if (responseCheckin.resultCode === '1000')
        navigate(`/?h=${hotelCode}&r=${confirmationNo}`, {
          state: {
            visitedConfirmationNo: confirmationNo,
          },
        });
      else throw new Error(`${responseCheckin.resultCode}, ${responseCheckin.msg}`);
    } catch (error) {
      setErrorMessageTitle('체크인 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const getReservationAndPolicy = async () => {
    try {
      setIsLoading(true);
      const tokenInfo = await getReservation();
      await getPolicy(tokenInfo);
    } catch (error) {
      console.log('error: ', JSON.stringify(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const savePolicyAgreeAndValidateRoom = _.debounce(
    async () => {
      try {
        setIsLoading(true);
        await savePolicyAgree();
        if (bookingItem.roomNo) {
          if (bookingItem.getRoomListYn) navigate(`/room/?h=${hotelCode}&r=${confirmationNo}`);
          else {
            if (bookingItem.totalAmount > 0) await requestPayment();
            else await requestCheckin();
          }
        } else navigate(`/room/?h=${hotelCode}&r=${confirmationNo}`);
      } catch (error) {
        console.log('error: ', JSON.stringify(error.message));
      } finally {
        setIsLoading(false);
      }
    },
    300,
    { maxWait: 1000 },
  );

  useEffect(() => {
    if (paymentYn === 'N') openPaymentFailPopup();
    getReservationAndPolicy();
  }, []);

  return (
    <>
      <TermsOfUseList
        bookingItem={bookingItem}
        termsOfUseList={termsOfUseList}
        setTermsOfUseList={setTermsOfUseList}
        savePolicyAgreeAndValidateRoom={savePolicyAgreeAndValidateRoom}
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <PaymentFail isOpen={isOpenPaymentFailPopup} onClose={closePaymentFailPopup} />
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default TermsOfUseListContainer;
